import { mobile, styled, Text } from '@obvio/app'
import { MaxHeight, Section as UiSection } from '@obvio/ui'

export const Section = styled(UiSection)`
  background: ${(theme) => theme.colors.secondary};
  margin: 0;
  padding: 0 !important;
  max-width: 100vw !important;
  width: 100vw;
  position: relative;
  height: 100vh;
  max-height: 1080px;

  > section {
    height: 100%;
  }
`

export const StyledMaxHeight = styled(MaxHeight)`
  max-height: 100%;
  background: ${(theme) => theme.colors.secondary};
`

export const Hero = styled.div`
  height: 100%;
  width: 100%;

  color: ${(theme) => theme.colors.primary};
  ${(theme) => theme.flexCenter}
`

export const ImageWrap = styled.div`
  position: absolute;
  inset: 0;
`

export const HeroVideoBlock = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  color: ${(theme) => theme.colors.primary};

  > div:first-of-type {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    scale: 1.25;

    @media (min-aspect-ratio: 16/9) {
      height: 300%;
      top: -100%;
    }

    @media (aspect-ratio: 16/9) {
      height: 100%;
      top: 0;
      width: 300%;
      left: -100%;
    }

    @media (max-aspect-ratio: 16/9) {
      width: 300%;
      left: -100%;
    }
    @media ${mobile} {
      width: 400%;
      height: 400%;
      top: -150%;
      left: -150%;
    }

    > iframe {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }
  }
  > div:last-of-type {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    ${(theme) => theme.flexCenter}
  }
`

export const CalendarPosition = styled.div`
  position: absolute;
  width: 100%;
  bottom: 5rem;
  z-index: 1;
  ${(theme) => theme.flexCenter}
  @media ${mobile} {
    bottom: 0;
  }
`

export const Title = styled(Text)`
  font-size: 6rem;
  text-transform: uppercase;
  z-index: 1;
  @media ${mobile} {
    font-size: 2.5rem;
  }
`
