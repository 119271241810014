import {
  css,
  Image,
  mobile,
  styled,
  Text,
  useInView,
  useMaxHeight,
  useRemToPx,
  useRouter,
} from '@obvio/app'
import { SvgAbstract, SvgMainLogo } from '@obvio/svg'
import { Stack } from '@obvio/ui'
import { motion, useAnimation } from 'framer-motion'
import NextImage from 'next/image'
import { forwardRef, useEffect, useMemo, useRef } from 'react'

import {
  CalendarPosition,
  Hero,
  HeroVideoBlock,
  ImageWrap,
  Section,
} from './HeroSection/components'
import { ScrollMore } from '@/components/Blog/BlogIndex/ScrollMore'
import { InlineBookCalendar } from '@/components/InlineBookCalendar/InlineBookCalendar'
import { InlineRestaurantReservation } from '@/components/InlineRestaurantReservation/InlineRestaurantReservation'
import { InlineSpaReservation } from '@/components/InlineSpaReservation/InlineSpaReservation'
import { useLayoutType } from '@/components/Layout'
import { useYtContext } from '@/components/YTContext'
import { heroSectionStore } from '@/utils/heroSectionStore'

import type { ImageAsset } from '@obvio/app'
import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement, ReactNode } from 'react'

type HeroSectionProps = AllowUndefinedForOptional<{
  type?: 'spa' | 'restaurant' | 'image' | 'post'
  img?: ImageAsset | string
  ytSrc?: string
  text?: string
  noLogo?: boolean
  seoTitle?: string
  // is text an h1 or p
  heading?: boolean
  hideForm?: boolean
  children?: ReactNode
}>

type HeroVideoProps = {
  src: string
  children: ReactElement[] | ReactElement
}

const PageTitle = styled(Text)`
  color: white;
  font-family: ${(theme) => theme.secondaryFont};
  font-size: 3rem;
  line-height: 135%;
  @media ${mobile} {
    font-size: 2.25rem;
  }
`

const MotionFadeIn = styled(motion.div)`
  height: 100%;
  width: 100%;
`

const StoreTitle = styled.span`
  text-transform: uppercase;
  margin-bottom: ${(theme) => theme.spacing.medium};
  display: block;
`

const RichTextWrap = styled.div`
  margin: 0 auto;
  z-index: 1;
  text-transform: uppercase;
  width: 48rem;
  text-align: center;
  max-width: 70%;
`

const ScrollWrap = styled.span`
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  @media ${mobile} {
    bottom: 6rem;
  }
`

const LandingStack = styled(Stack)`
  margin-top: -${(theme) => theme.spacing.large};
  svg {
    z-index: 1;
  }
  align-items: center;
`

const FadeIn = forwardRef<HTMLDivElement, { children: ReactElement }>(
  ({ children }, ref) => (
    <MotionFadeIn
      ref={ref}
      animate={{ background: ['rgba(49,53,44, 1)', 'rgba(49,53,44, 0.1)'] }}
      transition={{ duration: 1.5 }}
    >
      {children}
    </MotionFadeIn>
  ),
)

function HeroVideo({ children, src }: HeroVideoProps): ReactElement {
  const { Player, PlayerState } = useYtContext()
  const controls = useAnimation()
  const player = useRef()
  const frameRef = useRef<HTMLDivElement | null>(null)

  const parentRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (Player && frameRef.current) {
      player.current = new Player(frameRef.current.id, {
        videoId: src,
        playerVars: {
          cc_load_policy: 0, // closed caption
          controls: 0,
          disablekb: 0, // disable keyboard
          iv_load_policy: 3, // annotations
          playsinline: 1, // play inline on iOS
          rel: 0, // related videos
          showinfo: 0, // title
          modestbranding: 3, // youtube logo
        },
        events: {
          onReady: (event) => {
            event.target.playVideo()
            event.target.mute()
          },
          onStateChange: (event) => {
            if (event.data === PlayerState?.PLAYING) {
              void controls.start({
                opacity: 1,
              })
            }
            if (event.data === PlayerState?.ENDED) {
              event.target.playVideo()
            }
          },
        },
      })
    }
  }, [Player, PlayerState?.ENDED, PlayerState?.PLAYING, controls, src])

  return (
    <HeroVideoBlock>
      <motion.div
        ref={parentRef}
        animate={controls}
        initial={{ opacity: 0 }}
        transition={{ duration: 1.5 }}
      >
        <div id="hero-video-wrap" ref={frameRef} />
      </motion.div>
      <div>{children}</div>
    </HeroVideoBlock>
  )
}

type WrapProps = {
  children: ReactElement
  img: ImageAsset | undefined | string
  ytSrc: string | undefined
}

const Wrap = forwardRef<HTMLDivElement, WrapProps>(
  ({ children, img, ytSrc }, ref): ReactElement => {
    if (img) {
      return (
        <Hero ref={ref}>
          <ImageWrap>
            {typeof img === 'string' ? (
              <NextImage key={img} src={img} priority fill alt="Hero image" />
            ) : (
              <Image key={img?.name} img={img} priority />
            )}
          </ImageWrap>
          {children}
        </Hero>
      )
    }

    if (ytSrc) {
      return (
        <FadeIn ref={ref}>
          <HeroVideo src={ytSrc}>{children}</HeroVideo>
        </FadeIn>
      )
    }
    return (
      <div style={{ height: '100%' }} ref={ref}>
        {children}
      </div>
    )
  },
)

export function HeroSection({
  img,
  ytSrc,
  type = 'image',
  text,
  noLogo,
  heading,
  hideForm: propsHideForm,
  children,
}: HeroSectionProps): ReactElement {
  const { asPath } = useRouter()
  const maxHeightRef = useMaxHeight()
  const remToPx = useRemToPx()
  const layoutType = useLayoutType()
  const inViewOptions = useMemo(
    () => ({ rootMargin: `${remToPx(-2 - 2.25 / 2)}px` }),
    [remToPx],
  )
  const { ref: wrapRef, inView: inViewWrap } = useInView(inViewOptions)
  const [, dispatch] = heroSectionStore()

  useEffect(() => {
    dispatch('SET_HERO_VISIBILITY', inViewWrap)
  }, [inViewWrap, dispatch])

  const sectionCss = useMemo(
    () => css`
      background: ${(theme) => theme.colors.secondary};
      overflow: hidden;

      position: relative;
      :after {
        content: '';
        position: absolute;
        inset: 0;
        background: linear-gradient(
          0deg,
          rgba(15, 15, 15, 1) 0%,
          rgba(15, 15, 15, 0.25) 50%,
          rgba(15, 15, 15, 0) 84.24%
        );
      }
    `,
    [],
  )

  const isStore = layoutType === 'Sklep'
  const isLanding = asPath === '/'
  const hideForm = propsHideForm ?? asPath === '/biznes'

  return (
    <Section outerCss={sectionCss} ref={maxHeightRef}>
      <Wrap img={img} ytSrc={ytSrc} ref={wrapRef}>
        <>
          {children ? (
            <div>{children}</div>
          ) : noLogo && !isLanding ? null : isLanding ? (
            <LandingStack kind="vertical">
              <SvgAbstract />
              <SvgMainLogo />
            </LandingStack>
          ) : (
            text && (
              <RichTextWrap>
                {isStore && <StoreTitle>511 store</StoreTitle>}
                <PageTitle tag={heading ? 'h1' : 'p'} as="h2">
                  {text}
                </PageTitle>
              </RichTextWrap>
            )
          )}
          {type !== 'post' && !isStore && !hideForm ? (
            <CalendarPosition>
              {type === 'spa' || asPath === '/spa' ? (
                <InlineSpaReservation />
              ) : type === 'restaurant' || asPath === '/restauracja' ? (
                <InlineRestaurantReservation />
              ) : type === 'image' ? (
                <InlineBookCalendar />
              ) : null}
            </CalendarPosition>
          ) : null}
          <ScrollWrap>
            <ScrollMore />
          </ScrollWrap>
        </>
      </Wrap>
    </Section>
  )
}
